//
// Xtreme Angular
//
//(Required file)
@import "variable";
// Import Bootstrap source files (Required file)
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import "../../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@import '@angular/material/prebuilt-themes/indigo-pink.css';
//This is for the components (Required file)
@import "core/core";
@import "scafholdings";
@import "components";
//This is for the responsive (Required file)
@import "responsive";
//In This scss you can write your scss
@import "custom";