/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Arimo&family=Josefin+Sans:wght@400;500;700&family=Nixie+One&display=swap');
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/saga-blue/theme.css";
@import "~primeng/resources/primeng.min.css";
// @import '@angular/material/prebuilt-themes/indigo-pink.css';
// @import "~primeng/calendar/calendar.css";
@media only screen and (max-width: 600px) {
    .row {
        --bs-gutter-x: 0px;
    }
}

label {
    margin-bottom: 10px;
}